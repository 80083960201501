<template>
  <div class="bg-white">
    <div
      class="flex flex-col sm:flex-row sm:justify-between sm:items-center  px-4 py-4 mt-2 bg-white"
    >
      <TitlePlus
        :title="
          $t('components.riderDetailsManagement.diveIntoTab.pass.headline')
        "
        :hide-plus="true"
      />
      <DateRangePicker
        @apply-date="onApplyFilterDateRange"
        @calcel-date="onApplyFilterDateRange"
      />
    </div>

    <div
      class="flex flex-col px-4 mt-5 space-y-5 lg:items-center lg:space-y-0 lg:space-x-5 lg:flex-row mb-10"
    >
      <SummaryCard
        :title="
          $t(
            'components.riderDetailsManagement.diveIntoTab.pass.summary.totalPasses'
          )
        "
        :value="getTotalPass"
        variant="gray"
      />
    </div>

    <FSTable
      :fst-id="`riderPass`"
      :endpoint="
        `/dashboard/riders/${$route.params.id}/pass-subscription-records/`
      "
      :headers="getTableHeaders"
      @meta="(e) => (indexMetaData = e)"
    >
      <template #default="{ data }">
        <template v-for="(item, itemIndex) in data">
          <template v-if="getTableMode === 'full'">
            <FSTableRow :key="itemIndex" text-fallback-always>
              <FSTableRowItem
                :text="item.pass_id"
                :truncate="-5"
                :to="{ name: 'PassDetails', params: { id: item.pass_id } }"
              />

              <FSTableRowItem
                :text="getFormattedDate(item.pass_start_date, 'D MMM, YYYY')"
              />

              <FSTableRowItem
                :text="getFormattedDate(item.pass_end_date, 'D MMM, YYYY')"
              />

              <FSTableRowItem :text="item.pass_type" />

              <FSTableRowItem
                :text="`${symbol} ${item.spent_amount_from_pass}`"
              />

              <FSTableRowItem :text="`${symbol} ${item.pass_rate}`" />

              <FSTableRowItem>
                <x-status
                  :text="item.status"
                  :variant="getItemVariant(item.status)"
                />
              </FSTableRowItem> </FSTableRow
          ></template>
          <template v-if="getTableMode === 'responsive'">
            <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
              <FSTableRowItem>
                <div
                  class="col-span-1 focus:text-gray-400"
                  @click="toggle(itemIndex)"
                >
                  <i
                    class="fas fa-minus-circle"
                    style="color:#d90a20;"
                    v-if="opened.includes(itemIndex)"
                  ></i>
                  <i class="fas fa-plus-circle" v-else></i>
                </div>
              </FSTableRowItem>
              <FSTableRowItem
                :text="item.pass_id"
                :truncate="-5"
                :to="{ name: 'PassDetails', params: { id: item.pass_id } }"
              />
              <FSTableRowItem :text="item.pass_type" />
              <FSTableRowItem :text="`${symbol} ${item.pass_rate}`" />

              <FSTableRowItem>
                <x-status
                  :text="item.status"
                  :variant="getItemVariant(item.status)"
                />
              </FSTableRowItem>
            </FSTableRow>
            <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
              <td colspan="10">
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{
                      $t(
                        'components.riderDetailsManagement.diveIntoTab.pass.table.columns.startDate'
                      )
                    }}
                  </div>
                  <div class="col-span-5 right-text">
                    {{ getFormattedDate(item.pass_start_date, 'D MMM, YYYY') }}
                  </div>
                </div>
                <div class="grid grid-cols-10 items-center w-full expand-item">
                  <div class="col-span-5 left-text">
                    {{
                      $t(
                        'components.riderDetailsManagement.diveIntoTab.pass.table.columns.endDate'
                      )
                    }}
                  </div>
                  <div class="col-span-5 right-text">
                    {{ getFormattedDate(item.pass_end_date, 'D MMM, YYYY') }}
                  </div>
                </div>
              </td>
            </FSTableRow>
          </template>
        </template>
      </template>
    </FSTable>
  </div>
</template>

<script>
import DateRangePicker from '@/components/picker/date-range/DateRangePicker.vue'
// import OtoEyeIcon from '@/components/ui/OtoEyeIcon'
// import RefundPopup from '@/views/transactions/RefundPopup.vue'
import SummaryCard from '@/components/cards/SummaryCard'
import { getFormattedDate } from '@/utils/datetime'
import XStatus from '@/components/badge/XStatus'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'

export default {
  name: 'ViewRiderUserPass',
  components: {
    DateRangePicker,
    // OtoEyeIcon,
    // RefundPopup,
    SummaryCard,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    XStatus,
    TitlePlus: () => import('@/components/ui/TitlePlus'),
  },
  data() {
    return {
      symbol: '$',
      indexMetaData: {
        summary: {
          total: '0',
          balance: '0.00',
          current_pass: null,
        },
        count: {
          total: 0,
        },
      },
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.passId'
          ),
          width: '10%',
          sort: 'pass_id',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.startDate'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.endDate'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.passType'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.spentAmount'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.passDeposit'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.status'
          ),
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.passId'
          ),
          width: '20%',
          sort: 'pass_id',
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.passType'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.passDeposit'
          ),
          width: '25%',
          sort: null,
        },
        {
          text: this.$t(
            'components.riderDetailsManagement.diveIntoTab.pass.table.columns.status'
          ),
          width: '25%',
          sort: null,
        },
      ],
    }
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    getTotalPass() {
      return `${this.indexMetaData?.summary?.total}`
    },
    getPassBalance() {
      return `${this.symbol} ${this.indexMetaData?.summary?.balance}`
    },
  },
  mounted() {
    this.symbol = this?.$org?.default_currency?.symbol || '$'
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onApplyFilterDateRange,
    getFormattedDate,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getItemVariant(status) {
      console.log('item-status', status)
      if (status === 'Active') return 'green'
      if (status === 'Inactive') return 'gray'
      return 'red'
    },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
